<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-bototm: 0; padding-left: 0; padding-right: 0;">
    <v-layout wrap>
      <v-flex md12>
        <div class="ml-4 mb-2">
          <tab-header
            :tab-items="tabItems"
            :selected-id="selectedTab"
            @onSelectTab="onSelectTab" />
        </div>
        <router-view />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
// import functionUtils from 'utils/functionUtils'
import TabHeader from 'Components/TabHeader.vue'
const DECENTRALIZATION_TAB = {
  ENTITY_ROLE: 1,
  GROUP_ACTION: 2
}
export default {
  components: {
    TabHeader
  },
  data: () => ({
    tabItems: [
      {
        title: 'roles.entityRoleList',
        id: DECENTRALIZATION_TAB.ENTITY_ROLE
      },
      {
        title: 'decentralizationGroup.decentralizationGroupList',
        id: DECENTRALIZATION_TAB.GROUP_ACTION
      }
    ],
    pathRoles: {
      ENTITY_ROLE: '/decentralization',
      GROUP_ACTION: '/decentralization-groups'
    },
    selectedTab: DECENTRALIZATION_TAB.ENTITY_ROLE
  }),
  computed: {
    ...mapGetters([
    ])
  },
  watch: {
  },
  created () {
    this.checkRouteChanged(this.$route)
  },
  methods: {
    checkRouteChanged: function (toRoute) {
      this.showBar = (this.$route.path !== '/product-list' || this.$route.path !== '/wap-home')
      if (this.$route.path === this.pathRoles.ENTITY_ROLE) {
        this.selectedTab = DECENTRALIZATION_TAB.ENTITY_ROLE
      } else if (this.$route.path === this.pathRoles.GROUP_ACTION) {
        this.selectedTab = DECENTRALIZATION_TAB.GROUP_ACTION
      }
    },
    onSelectTab: function ({ index, item }) {
      var path = this.pathRoles.ENTITY_ROLES
      if (item.id === DECENTRALIZATION_TAB.ENTITY_ROLE) {
        path = this.pathRoles.ENTITY_ROLE
      } else if (item.id === DECENTRALIZATION_TAB.GROUP_ACTION) {
        path = this.pathRoles.GROUP_ACTION
      }
      this.$router.push({
        path: path
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
